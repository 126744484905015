import React from 'react';
import { AudioVideoBadgesSection } from 'views/audio-video/audio-video-badges-section';
import { AudioVideoMainSection } from 'views/audio-video/audio-video-main-section';
import { AudioVideoRowsSection } from 'views/audio-video/audio-video-rows-section';
import { StreamingAdvantagesSection } from 'views/audio-video/streaming-advantages-section';
import { StreamingBenefitsSection } from 'views/audio-video/streaming-business-benefits-section';
import { StreamingCaseStudiesSection } from 'views/audio-video/streaming-case-studies-section';
import { YoutubePlayer } from 'components/youtube-player';
import { AIAssistantSection } from 'modules/ai-assistant-section';
import { FooterSection } from 'modules/footer-section';
import { array } from 'prop-types';

import { LATEST_RELATED_ARTICLES_SETTINGS } from './constants';

const Props = {
    faqData: array.isRequired,
};

export const AudioVideoView = ({ faqData }) => {
    return (
        <>
            <AudioVideoMainSection />
            <StreamingAdvantagesSection />
            <AIAssistantSection />
            <AudioVideoBadgesSection />
            <StreamingBenefitsSection />
            <AudioVideoRowsSection />
            <YoutubePlayer
                videoId="e0GTIuWjDyw"
                title="audio-video-page.youtubeTitle"
            />
            <StreamingCaseStudiesSection />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </>
    );
};

AudioVideoView.propTypes = Props;
